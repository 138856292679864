const state = () => ({
	sidebar:[],
    company: {},
	modules: []
})

const getters = {
	getSidebar: state => state.sidebar,
	getCompany: state => state.company,
	getModules: state => state.modules,
	getCompanyLogo: state => state.company.logo,
}

const mutations = {
	setSidebar: (state, payload) => state.sidebar = payload,
	setCompany: (state, payload) => state.company = payload,
	setModules: (state, payload) => state.modules = payload,
	updateCompanyInfo: (state, data) => {
		state.company.address	= data.address
		state.company.email		= data.email
		state.company.id		= data.id
		state.company.name		= data.name
		state.company.phone		= data.phone
		state.company.vat_no	= data.vat_no
		state.company.tin_no	= data.tin_no
		state.company.reg_no	= data.reg_no
		state.company.industry	= data.industry
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}