export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/staff-register/:pageId',
        component: () => import('@/views/page/company/sdm/staff-register/List.vue'),
        name: 'staff-register',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/dealer-register/:pageId',
        component: () => import('@/views/page/company/sdm/dealer-register/register/Index.vue'),
        name: 'dealer-register',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/add-dealer/:pageId',
        component: () => import('@/views/page/company/sdm/dealer-register/Add.vue'),
        name: 'add-dealer',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sdm-sales-order/:pageId',
        component: () => import('@/views/page/company/sdm/order-sales/AddOrder.vue'),
        name: 'sdm-sales-order',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sdm-sales-order-list/:pageId',
        component: () => import('@/views/page/company/sdm/order-sales/List.vue'),
        name: 'sdm-sales-order-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-allocation/:pageId',
        component: () => import('@/views/page/company/sdm/sales-allocation/List.vue'),
        name: 'sales-allocation',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-commissions/:pageId',
        component: () => import('@/views/page/company/sdm/sales-commision/List.vue'),
        name: 'sales-commissions',
        meta: {
            requiresAuth: true
        }
    },
]