export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/day-book/:pageId',
        component: () => import('@/views/page/company/core-accounting/accounting-report/DayBook.vue'),
        name: 'day-book',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/general-ledger-old/:pageId',
        component: () => import('@/views/page/company/core-accounting/accounting-report/GeneralLedgerOld.vue'),
        name: 'general-ledger-old',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/general-ledger/:pageId',
        component: () => import('@/views/page/company/core-accounting/accounting-report/GeneralLedger.vue'),
        name: 'general-ledger',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/trial-balance/:pageId',
        component: () => import('@/views/page/company/core-accounting/accounting-report/TrialBalance.vue'),
        name: 'trial-balance',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/balance-sheet/:pageId',
        component: () => import('@/views/page/company/core-accounting/accounting-report/BalanceSheet.vue'),
        name: 'balance-sheet',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/fs-datasheet/:pageId',
        component: () => import('@/views/page/company/core-accounting/accounting-report/BalanceSheetColumnwise.vue'),
        name: 'fs-datasheet',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/profit-loss/:pageId',
        component: () => import('@/views/page/company/core-accounting/accounting-report/ProfitLoss.vue'),
        name: 'profit-loss',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/payable-register',
        component: () => import('@/views/page/company/core-accounting/accounting-report/Payable.vue'),
        name: 'payable-register-dashboard',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/receivable-register',
        component: () => import('@/views/page/company/core-accounting/accounting-report/Receivable.vue'),
        name: 'receivable-register-dashboard',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/cash-bank-register',
        component: () => import('@/views/page/company/core-accounting/accounting-report/CashAndBank.vue'),
        name: 'cash-bank-register-dashboard',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/project-budget-balance-summary',
        component: () => import('@/views/page/company/core-accounting/accounting-report/ProjectBudgetBalanceSummary.vue'),
        name: 'project-budget-balance-summary-dashboard',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/project-cash-bank-balance-summary',
        component: () => import('@/views/page/company/core-accounting/accounting-report/ProjectWiseCashBankBalance.vue'),
        name: 'project-cash-bank-balance-summary-dashboard',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/fd6-budget-balance-summary',
        component: () => import('@/views/page/company/core-accounting/accounting-report/Fd6BudgetBalanceSummary.vue'),
        name: 'fd6-budget-balance-summary-dashboard',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/expense-category-summary',
        component: () => import('@/views/page/company/core-accounting/accounting-report/ExpenseCategorySummary.vue'),
        name: 'expense-category-summary-dashboard',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/expense-project-summary',
        component: () => import('@/views/page/company/core-accounting/accounting-report/ExpenseProjectSummary.vue'),
        name: 'expense-project-summary-dashboard',
        meta: {
            requiresAuth: true
        }
    },
    //=================================
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/payable-register',
        component: () => import('@/views/page/company/core-accounting/accounting-report/Payable.vue'),
        name: 'payable-register',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/doctor-incentive-report',
        component: () => import('@/views/page/company/core-accounting/accounting-report/DoctorIncentiveReport.vue'),
        name: 'doctor-incentive-report',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/doctor-incentive-detailed-report/:serviceId',
        component: () => import('@/views/page/company/core-accounting/accounting-report/DoctorIncentiveDetailedReport.vue'),
        name: 'doctor-incentive-detailed-report',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/receivable-register',
        component: () => import('@/views/page/company/core-accounting/accounting-report/Receivable.vue'),
        name: 'receivable-register',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/receivable-aging',
        component: () => import('@/views/page/company/core-accounting/accounting-report/ReceivableAging.vue'),
        name: 'receivable-aging',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/cash-bank-register',
        component: () => import('@/views/page/company/core-accounting/accounting-report/CashAndBank.vue'),
        name: 'cash-bank-register',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/receipts-payments-report/:pageId',
        component: () => import('@/views/page/company/core-accounting/accounting-report/ReceiptsPaymentsReport.vue'),
        name: 'receipts-payments-report',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/bank-reconciliation/:pageId',
        component: () => import('@/views/page/company/core-accounting/accounting-report/BankReconciliationList.vue'),
        name: 'bank-reconciliation',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/bank-reconciliation-create/:pageId',
        component: () => import('@/views/page/company/core-accounting/accounting-report/BankReconciliationCreate.vue'),
        name: 'bank-reconciliation-create',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/bank-reconciliation-print/:pageId',
        component: () => import('@/components/molecule/company/bank-reconciliation/BankReconciliationPrint.vue'),
        name: 'bank-reconciliation-print',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/party-ledger/:pageId',
        component: () => import('@/views/page/company/core-accounting/accounting-report/PartyLedger.vue'),
        name: 'party-ledger',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/party-ledger-old/:pageId',
        component: () => import('@/views/page/company/core-accounting/accounting-report/PartyLedgerOld.vue'),
        name: 'party-ledger-old',
        meta: {
            requiresAuth: true
        }
    },
]
